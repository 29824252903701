import { createTheme } from '@mui/material';
import { ThemeOptions } from '@mui/material/styles/createTheme';

const webApp = window.Telegram.WebApp;
const { colorScheme, themeParams } = webApp;
// eslint-disable-next-line @typescript-eslint/naming-convention
const { bg_color, button_color, text_color, hint_color } = themeParams;

function getTheme(): ThemeOptions {
  if (!bg_color) {
    return {
      spacing: 8,
    };
  }
  return {
    spacing: 8,
    palette: {
      mode: colorScheme,
      primary: {
        main: button_color,
      },
      background: {
        default: bg_color,
      },
      text: {
        primary: text_color,
        secondary: hint_color,
      },
    },
  };
}

export const theme = createTheme(getTheme());

import React, { FunctionComponent, StrictMode, useEffect } from 'react';
import { HashRouter as Router } from 'react-router-dom';

import styled from '@emotion/styled';
import { CssBaseline, Divider, ThemeProvider } from '@mui/material';
import { reatomContext } from '@reatom/react';

import { ItemList } from './features/menu';
import { orderAtom, OrderAtomState } from './features/order/model';
import { makeOrderRequest } from './features/order/request';
import { theme } from './features/theme';
import { store } from './store';

const { Provider: StateProvider } = reatomContext;

const Root = styled.div`
  display: flex;
`;

const ContentWrapper = styled('main')`
  flex-grow: 1;
`;

export const AppContent = () => {
  useEffect(() => {
    window.Telegram.WebApp.ready();
    window.Telegram.WebApp.expand();
  }, []);
  return (
    <Root>
      <CssBaseline />
      <ContentWrapper>
        <ItemList />
        <Divider />
      </ContentWrapper>
    </Root>
  );
};

export const App: FunctionComponent = () => (
  <StrictMode>
    <Router>
      <StateProvider value={store}>
        <ThemeProvider theme={theme}>
          <AppContent />
        </ThemeProvider>
      </StateProvider>
    </Router>
  </StrictMode>
);

const makeOrderText = 'Оформить заказ';
const MainButton = window.Telegram.WebApp.MainButton;

store.subscribe<OrderAtomState>(orderAtom, (orderState) => {
  if (Object.keys(orderState).length > 0) {
    MainButton.show();
  } else {
    MainButton.hide();
  }
});

async function onOrderCreate() {
  const orderState = store.getState(orderAtom);
  if (Object.keys(orderState).length === 0) {
    return;
  }
  MainButton.showProgress();
  try {
    await makeOrderRequest(orderState);

    MainButton.hideProgress();
    window.Telegram.WebApp.close();
  } catch (error) {
    MainButton.setText(`Shit happens ${error.name}`);
    setTimeout(() => {
      MainButton.setText(makeOrderText);
      MainButton.hideProgress();
    }, 1000);
  }
}

MainButton.onClick(onOrderCreate);
MainButton.setText(makeOrderText);

import React from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  // Collapse,
  // styled,
  Typography,
} from '@mui/material';

import { useAction, useAtom } from '@reatom/react';
import { CountButtons, CountEventType } from '@/features/menu/count-buttons';
import { orderAtom } from '@/features/order/model';
import { Product } from '@/features/products/list';

// interface ExpandMoreProps extends IconButtonProps {
//   expand: boolean;
// }

// const ExpandMore = styled((props: ExpandMoreProps) => {
//   const { expand, ...other } = props;
//   return <IconButton {...other} />;
// })(({ theme, expand }) => ({
//   transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
//   marginLeft: 'auto',
//   transition: theme.transitions.create('transform', {
//     duration: theme.transitions.duration.shortest,
//   }),
// }));

export type MenuItemProps = {
  item: Product;
};
export const MenuItem = ({ item }: MenuItemProps) => {
  const [data] = useAtom(orderAtom, (atomState) => atomState[item.name], [
    item.name,
  ]);

  const onChange = useAction(
    (eventType: CountEventType) => {
      if (eventType === 'add') {
        return orderAtom.addItem(item.name);
      }
      return orderAtom.subtractItem(item.name);
    },
    [item.name],
  );

  // const [expanded, setExpanded] = useState(false);

  // const handleExpandClick = () => {
  //   setExpanded(!expanded);
  // };

  return (
    <Card>
      <CardHeader title={item.name} />
      <CardContent>
        <Typography variant="body2">Цена: {item.price}</Typography>
      </CardContent>
      <CardActions disableSpacing>
        <CountButtons
          onCount={onChange}
          countValue={data ? data.quantity.toString() : '0'}
        />
        {/*<ExpandMore*/}
        {/*  expand={expanded}*/}
        {/*  onClick={handleExpandClick}*/}
        {/*  aria-expanded={expanded}*/}
        {/*  aria-label="show more"*/}
        {/*>*/}
        {/*  <ExpandMoreIcon />*/}
        {/*</ExpandMore>*/}
      </CardActions>
      {/*<Collapse in={expanded} timeout="auto" unmountOnExit>*/}
      {/*  <CardContent>*/}
      {/*    <Typography paragraph>ItemId:</Typography>*/}
      {/*    <Typography paragraph>{item.name}</Typography>*/}
      {/*    <Typography component="pre">*/}
      {/*      {JSON.stringify(data, null, 2)}*/}
      {/*    </Typography>*/}
      {/*  </CardContent>*/}
      {/*</Collapse>*/}
    </Card>
  );
};

import React, { useCallback } from 'react';

import { Button, ButtonGroup, Typography } from '@mui/material';

export type CountEventType = 'add' | 'subtract';

export type CountButtonsProps = {
  countValue: string;
  onCount: (eventType: CountEventType) => void;
};

export const CountButtons = ({ onCount, countValue }: CountButtonsProps) => {
  const onAdd = useCallback(() => {
    onCount('add');
  }, []);

  const onSubtract = useCallback(() => {
    onCount('subtract');
  }, []);

  return (
    <ButtonGroup color="primary" sx={{ ml: 1 }}>
      <Button onClick={onSubtract}>-</Button>
      <Button disabled>
        <Typography color="text.primary">{countValue}</Typography>
      </Button>
      <Button onClick={onAdd}>+</Button>
    </ButtonGroup>
  );
};

import * as React from 'react';
import { AppBar, Box, Grid, Tab, Tabs } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';

import { MenuItem } from './menu-item';
import { Product, productList } from '@/features/products/list';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: any;
}

const TabPanel = ({ children, value, index, ...other }: TabPanelProps) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && <Box p={3}>{children}</Box>}
  </div>
);

const a11yProps = (index: number) => ({
  id: `full-width-tab-${index}`,
  'aria-controls': `full-width-tabpanel-${index}`,
});

export type SubMenuListProps = {
  items: Product[];
};

const SubmenuList = ({ items }: SubMenuListProps) => (
  <Grid container spacing={2}>
    {items.map((item) => (
      <Grid item key={`item-${item.name}`} xs={11}>
        <MenuItem item={item} />
      </Grid>
    ))}
  </Grid>
);

let currentMenu = '';

type MenuStructure = {
  itemCats: { name: string }[];
  itemMatrix: { [key: string]: Product[] };
};
const structure: MenuStructure = productList.reduce<MenuStructure>(
  (acc, item) => {
    if (item.type === 'delimiter') {
      currentMenu = item.name;
      acc.itemCats.push({ name: item.name });
      acc.itemMatrix[currentMenu] = [];
    } else if (item.type === 'product') {
      acc.itemMatrix[currentMenu].push(item);
    }
    return acc;
  },
  { itemCats: [], itemMatrix: {} },
);
export const ItemList = () => {
  const [num, setNum] = React.useState(0);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent, newValue: number) => {
      setNum(newValue);
    },
    [],
  );

  const handleChangeIndex = React.useCallback((index: number) => {
    setNum(index);
  }, []);
  const { itemCats, itemMatrix } = structure;
  return (
    <Box
      sx={{
        height: window.Telegram.WebApp.viewportHeight - 1,
        width: '100vw',
        display: 'flex',
      }}
    >
      <AppBar color="default">
        <Tabs
          value={num}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
        >
          {itemCats.map((itemCat, index) => (
            <Tab
              label={itemCat.name}
              key={itemCat.name}
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      <SwipeableViews
        style={{ flexGrow: 1, marginTop: '20px' }}
        slideStyle={{ minHeight: 'calc(100vh - 54px)' }}
        index={num}
        onChangeIndex={handleChangeIndex}
        enableMouseEvents
      >
        {Object.entries(itemMatrix).map(([title, items], index) => (
          <Box sx={{ flexGrow: 1 }}>
            <TabPanel value={num} index={index} key={title}>
              <SubmenuList items={items} />
            </TabPanel>
          </Box>
        ))}
      </SwipeableViews>
    </Box>
  );
};

import { createAtom } from '@reatom/core';

export type OrderItem = {
  itemId: string;
  quantity: number;
};

export type OrderAtomState = Record<string, OrderItem>;

const generateOrder = (itemId: string): OrderItem => ({ itemId, quantity: 0 });

export const orderAtom = createAtom(
  {
    addItem: (itemId: string) => itemId,
    subtractItem: (itemId: string) => itemId,
  },
  (track, state: OrderAtomState = {}) => {
    track.onAction('addItem', (itemId) => {
      const order = state[itemId] || generateOrder(itemId);
      order.quantity += 1;
      state = {
        ...state,
        [itemId]: { ...order },
      };
    });
    track.onAction('subtractItem', (itemId) => {
      const order = state[itemId] || generateOrder(itemId);
      order.quantity = Math.max(order.quantity - 1, 0);
      if (order.quantity === 0) {
        state = { ...state };
        delete state[itemId];
      } else {
        state = {
          ...state,
          [itemId]: { ...order },
        };
      }
    });

    return state;
  },
);

export type Delimiter = {
  type: 'delimiter';
  name: string;
};
export type Product = {
  type: 'product';
  name: string;
  importer: string;
  price: string;
};

export type IProductList = Delimiter | Product;

export const productList: IProductList[] = [
  { type: 'delimiter', name: 'Виски' },
  {
    type: 'product',
    name: 'Вильям Лоусонс 0,5л виски',
    importer: 'Бакарди',
    price: '1780₽',
  },
  {
    type: 'product',
    name: 'Джонни Уокер Рэд Лейбл 0,5л виски',
    importer: 'Денвью',
    price: '2000₽',
  },
  {
    type: 'product',
    name: 'Джонни Уокер Рэд Лейбл 0,7л виски',
    importer: 'Денвью',
    price: '2200₽',
  },
  {
    type: 'product',
    name: 'Баллантайнс Файнест 0.5л виски Шотландия',
    importer: 'Перно',
    price: '2000₽',
  },
  {
    type: 'product',
    name: 'Баллантайнс Файнест0,7л виски Шотландия',
    importer: 'Перно',
    price: '2400₽',
  },
  {
    type: 'product',
    name: 'Чивас Ригал 12 лет 0,5л (под/упак)виски',
    importer: 'Перно',
    price: '3600₽',
  },
  {
    type: 'product',
    name: 'Макаллан Дабл Каск 12 лет 0,5л (под/упак) виски',
    importer: 'Денвью',
    price: '8800₽',
  },
  { type: 'delimiter', name: 'Бурбон' },
  {
    type: 'product',
    name: 'Джим Бим 0,5л.бел.этик.бурбон США',
    importer: 'Денвью',
    price: '2600₽',
  },
  {
    type: 'product',
    name: 'КАРУС УНУМ КЕНТУККИ СТРАЙТ БУРБОН 0,7л.',
    importer: 'СТМ',
    price: 'UNKNOWN',
  },
  { type: 'delimiter', name: 'Коньяк' },
  {
    type: 'product',
    name: 'Хеннесси ВС 0,7л (под/упак) коньяк',
    importer: 'МХРУ',
    price: '4500₽',
  },
  {
    type: 'product',
    name: 'Мартель ВС Сингл Дистиллери 0,5л.(п/у) коньяк Франция',
    importer: 'Перно',
    price: '3500₽',
  },
  {
    type: 'product',
    name: 'Мартель ВСОП 0,7л ( п/у ) коньяк',
    importer: 'Перно',
    price: 'UNKNOWN',
  },
  {
    type: 'product',
    name: 'Ной Традиционный 0,5л трехлетний коньяк',
    importer: 'Белуга',
    price: '1900₽',
  },
  {
    type: 'product',
    name: 'Ной Традиционный 0,5л пятилетний коньяк',
    importer: 'Белуга',
    price: '2200₽',
  },
  { type: 'delimiter', name: 'Ром' },
  {
    type: 'product',
    name: 'Закапа Сэнтэнарио Солера Гран Резерва 23 0,7л п/у ром',
    importer: 'Денвью',
    price: '8000₽',
  },
  {
    type: 'product',
    name: 'Бругал Экстра Вьехо 0,7л ром Доминикана',
    importer: 'Денвью',
    price: '3800₽',
  },
  {
    type: 'product',
    name: 'Плантейшн Гран Аньехо Гватемала 0,7л.п/у ром.выд.',
    importer: 'Белуга',
    price: '3500₽',
  },
  {
    type: 'product',
    name: 'Бакарди Карта Бланка 0,5л ром',
    importer: 'Бакарди',
    price: '2200₽',
  },
  {
    type: 'product',
    name: 'Бакарди Карта Нэгра 0,5л ром',
    importer: 'Бакарди',
    price: '2200₽',
  },
  { type: 'delimiter', name: 'Текила' },
  {
    type: 'product',
    name: 'ДОН РОБЕРТО 1924 Плата (Серебряная) 0,75л текила',
    importer: 'Арома',
    price: '4000₽',
  },
  { type: 'delimiter', name: 'Джин' },
  {
    type: 'product',
    name: 'Бифитер 0,7л джин Великобр.',
    importer: 'Перно',
    price: '3000₽',
  },
  {
    type: 'product',
    name: 'Хендрикс 0,7л джин Великобритания 1х6',
    importer: 'Белуга',
    price: '8000₽',
  },
  {
    type: 'product',
    name: 'Джин Бомбей Сапфир Драй 0,7л.Великобр.',
    importer: 'Бакарди',
    price: '3600₽',
  },
  { type: 'delimiter', name: 'Водка' },
  {
    type: 'product',
    name: 'Царская Золотая 0,5л.водка',
    importer: 'Ладога',
    price: '850₽',
  },
  {
    type: 'product',
    name: 'Чистые Росы 0,5л водка',
    importer: 'Ладога',
    price: '1900₽',
  },
  {
    type: 'product',
    name: 'Белуга Нобл (BELUGA NOBLE) 0,5л водка',
    importer: 'Белуга',
    price: '1800₽',
  },
  { type: 'delimiter', name: 'Игристое' },
  {
    type: 'product',
    name: 'Виктор Дравиньи  0,75л.(ЗГУ)выд.роз.брют',
    importer: 'Абрау',
    price: '1700₽',
  },
  {
    type: 'product',
    name: 'Виктор Дравиньи 0,75л.(ЗГУ)выд.бел.брют игристое',
    importer: 'Абрау',
    price: '1700₽',
  },
  {
    type: 'product',
    name: 'Абрау-Дюрсо классическое 0,75л бел.п/сух.выдерж.',
    importer: 'Абрау',
    price: '600₽',
  },
  {
    type: 'product',
    name: 'Жардан Флёри 0,75л.бел.брют.игристое',
    importer: 'ИССИ',
    price: '1800₽',
  },
  {
    type: 'product',
    name: 'Леопольд Леман Креман де Бордо 0,75л.роз.',
    importer: 'ИССИ',
    price: '2000₽',
  },
  {
    type: 'product',
    name: 'Леопольд Леман Креман де Бордо 0,75л.бел.брют.',
    importer: 'ИССИ',
    price: '2000₽',
  },
  {
    type: 'product',
    name: 'Просекко Экстра Драй Гаэтано 0,75л бел.сух.игристое',
    importer: 'ИССИ',
    price: '1800₽',
  },
  {
    type: 'product',
    name: 'Просекко Брют Буон Боско 0,75л.игристое белое Италия',
    importer: 'КАО',
    price: '1500₽',
  },
  { type: 'delimiter', name: 'Вина тихие' },
  {
    type: 'product',
    name: 'Жардан Флёри Шардоне Вионье Пеи д`Ок 0,75л.бел.сух.',
    importer: 'ИССИ',
    price: '1525₽',
  },
  {
    type: 'product',
    name: 'Жардан Флёри Пино Нуар Пеи д`Ок 0,75л.крас.сух.вино',
    importer: 'ИССИ',
    price: '1500₽',
  },
  {
    type: 'product',
    name: 'Пино Гриджио Делле Венецие Гаэтано 0,75л бел.п/сух.',
    importer: 'ИССИ',
    price: '1500₽',
  },
  {
    type: 'product',
    name: 'Сент Клер Совиньон Блан Мальборо 0,75л.бел.сух.',
    importer: 'ИССИ',
    price: '1400₽',
  },
  {
    type: 'product',
    name: 'ГринЛайф Совиньон Блан Мальборо 0,75л.бел.сух.вино',
    importer: 'ИССИ',
    price: '2000₽',
  },
  {
    type: 'product',
    name: 'Рислинг Пфальц Соннентал 0,75л.бел.п/сух.вино',
    importer: 'ИССИ',
    price: '1550₽',
  },
  {
    type: 'product',
    name: 'Кьянти Албенза Тоскана 0,75л кр.сух.вино Италия',
    importer: 'КАО',
    price: '1550₽',
  },
  {
    type: 'product',
    name: 'Алазанская Долина 0,75л.кр.п/сл.вино Марани',
    importer: 'Форт',
    price: '1600₽',
  },
  {
    type: 'product',
    name: 'Уроки французского Мерло 0,75л.крас.сух.вино 1х6',
    importer: 'Шато Де ТаЛю',
    price: '1500₽',
  },
  {
    type: 'product',
    name: 'Уроки французского Каберне 0,75л.крас.сух.вино 1х6',
    importer: 'Шато Де ТаЛю',
    price: '1500₽',
  },
  {
    type: 'product',
    name: 'Уроки французского Совиньон 0,75л бел.сух.вино 1х6',
    importer: 'Шато Де ТаЛю',
    price: '1756₽',
  },
  {
    type: 'product',
    name: 'Уроки французского Шардоне 0,75л бел.сух.вино 1х6',
    importer: 'Шато Де ТаЛю',
    price: '1459₽',
  },
];

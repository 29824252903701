const initData = window.Telegram.WebApp.initData;

export async function serverRequest(
  path: string,
  data?: Record<string, unknown>,
  method?: RequestInit['method'],
) {
  const config: RequestInit = {
    method: method || 'POST',
    headers: {
      'Content-Type': 'application/json',
      'x-request-tgdata': initData,
    },
  };

  if (data) {
    config.body = JSON.stringify(data);
  }

  const res = await fetch(path, config);
  return res.json();
}
